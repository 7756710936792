<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $t('messages') }}</h5>
    <hr>
    <b-table striped bordered hover :items="messages" :fields="columns">
      <div slot="order" slot-scope="row" class="text-right">{{ row.index + 1 }}</div>
      <div slot="name" slot-scope="row">{{ row.item.name }}</div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" @click="show(row.item)"><i class="fa fa-eye"></i></b-button>
          <b-button variant="danger" class="mx-2" @click="destroy(row.item.id)"><i class="fa fa-trash px-1"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalDetail" :title="$t('details')" centered>
      <p>{{ $t('from') }} <strong>{{ message.sender }}</strong></p>
      <p>{{ $t('message') }} <strong>{{ message.message }}</strong></p>
      <hr>
      <span class="text-muted">{{ $t('date') }}: {{ message.created_at }}</span>
      <template slot="modal-footer">
        <b-button type="button" @click="$refs.modalDetail.hide()">{{ $t('close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      messages: [],
      message: {},
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'sender', label: this.$t('from'), sortable: false },
        { key: 'created_at', label: this.$t('date'), sortable: false },
        { key: 'actions', label: '', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    destroy (messageId) {
      const path = `auth/${this.lang}/${this.console}/users/messages/${messageId}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'))
        this.messages = this.messages.filter(not => {
          return not.id !== messageId
        })
      })
    },
    show (message) {
      this.message = message
      this.$refs.modalDetail.show()
      if (!message.viewed) {
        const path = `auth/${this.lang}/${this.console}/users/messages/${this.message.id}/show`
        this.$axios.post(path).then(() => {
          this.messages = this.messages.map(not => {
            if (not.id === this.message.id) {
              not.viewed = true
              not._rowVariant = ''
            }
            return not
          })
        })
      }
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/users/messages`
      this.$axios.get(path).then(response => {
        this.messages = response.data.data.map(not => {
          if (not.viewed === true) {
            not._rowVariant = ''
          } else {
            not._rowVariant = 'info'
          }
          return not
        })
      })
    }
  }
}
</script>
